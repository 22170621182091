import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | TechRevive PC Repair Service
			</title>
			<meta name={"description"} content={"Welcome to TechRevive PC Repair Service, where we understand the critical role your PC plays in your daily life. "} />
			<meta property={"og:title"} content={"Home | TechRevive PC Repair Service"} />
			<meta property={"og:description"} content={"Welcome to TechRevive PC Repair Service, where we understand the critical role your PC plays in your daily life. "} />
			<meta property={"og:image"} content={"https://huirabanstale.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://huirabanstale.com/img/5610074.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://huirabanstale.com/img/5610074.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://huirabanstale.com/img/5610074.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://huirabanstale.com/img/5610074.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://huirabanstale.com/img/5610074.png"} />
			<meta name={"msapplication-TileImage"} content={"https://huirabanstale.com/img/5610074.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0px 0 0px 0" background="--color-primary" md-padding="96px 0 0px 0" sm-padding="72px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="60px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					TechRevive PC Repair Service
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					lg-width="80%"
				>
					Welcome to TechRevive PC Repair Service, where we understand the critical role your PC plays in your daily life. Our goal is to provide quick, reliable, and affordable repair solutions for all your computer needs. With a team of expert technicians and a passion for technology, we ensure your PC is in the best hands.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="/contact"
						padding="12px 24px 12px 24px"
						color="--dark"
						background="transparent"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-light"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						sm-width="100%"
						hover-transition="background-color 0.2s ease-in-out 0s"
						border-width="1px"
						border-style="solid"
						border-color="--color-dark"
					>
						Contact Us
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			>
				<Image
					src="https://huirabanstale.com/img/1.jpg"
					width="555px"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					sm-width="100%"
				/>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-1">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				align-items="end"
				grid-gap="0 80px"
				lg-grid-gap="0 50px"
				md-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px" md-margin="0px 0px 50px 0px">
					<Text margin="0px 0px 20px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
						Why Choose TechRevive?
					</Text>
					<Text margin="0px 0px 70px 0px" font="--lead" lg-margin="0px 0px 40px 0px" color="#626970">
						Expert Technicians: Our team has extensive experience and is well-versed in handling a range of PC issues, from hardware malfunctions to software glitches.
					</Text>
					<Image
						src="https://huirabanstale.com/img/2.jpg"
						display="block"
						width="100%"
						height="600px"
						object-fit="cover"
						border-radius="15px"
						sm-height="400px"
					/>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Box
						min-width="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans">
							Fast Turnaround Time:
						</Text>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							We value your time. Our efficient diagnostic and repair process ensures your PC is back in your hands as quickly as possible.
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						margin="0px 0px 30px 0px"
						lg-margin="0px 0px 35px 0px"
						border-width="0 0 1px 0"
						border-style="solid"
						border-color="--color-lightD2"
						padding="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans">
							Transparent Pricing:
						</Text>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							No hidden costs here. We believe in honest, upfront pricing based on the specific repair needs of your PC.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 70px 0px" lg-margin="0px 0px 40px 0px">
						<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans">
							Personalized Customer Service:{" "}
						</Text>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							Each client is unique, and so is their tech issue. We provide personalized service to meet your specific needs.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 70px 0px" lg-margin="0px 0px 40px 0px">
						<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans">
							Quality Assurance:{" "}
						</Text>
						<Text margin="0px 0px 20px 0px" font="--base" color="#626970">
							Your satisfaction is our priority. We use only high-quality parts for repairs and offer service warranties for your peace of mind.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="40px 0 80px" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Contact Us
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				TechRevive - Where Your PC Problems Meet Their Solutions
			</Text>
			<Button
				font="--lead"
				margin="20px"
				href="/contact"
				type="link"
				text-decoration-line="initial"
			>
				Find Us
			</Button>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});